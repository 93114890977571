import CryptoJS from 'crypto-js'
import { getLS, saveLS, removeLS } from '../../helper/LocalStorage'

// Generates a secure random string for the PKCE challenge
const generateCodeVerifier = () => {
	const random = CryptoJS.lib.WordArray.random(128 / 8).toString(
		CryptoJS.enc.Hex
	)
	return random
}

// Generates the SHA256 hash of the input text. Encodes as base64url
const generateCodeChallenge = codeVerifier => {
	const hash = CryptoJS.SHA256(codeVerifier)
	const base64Str = hash.toString(CryptoJS.enc.Base64)
	const base64Url = base64Str
		.replace(/\+/g, '-')
		.replace(/\//g, '_')
		.replace(/=+$/, '')
	return base64Url
}

export const loginUrl = (redirectUri, clientId, ssoId) => {
	const codeVerifier = generateCodeVerifier()
	sessionStorage.setItem('pkce_code_verifier', codeVerifier)
	const codeChallenge = generateCodeChallenge(codeVerifier)

	if (ssoId) saveLS('sso_id', ssoId)
	else ssoId = getLS('sso_id')
	if (ssoId === 'delete') removeLS('sso_id')

	// TODO: Get this from Fileserver
	const matchingTenantidWithSSOId = {
		spar: '444',
	}

	const tenantId = matchingTenantidWithSSOId[ssoId] || ''

	const url = `${process.env.REACT_APP_CLIENT}/Authorize?tenant_id=${tenantId}&access_type=online&scope=openid&response_type=code&redirect=true&client_id=${encodeURIComponent(clientId)}&redirect_uri=${encodeURIComponent(redirectUri)}&code_challenge=${codeChallenge}`

	return url
}
