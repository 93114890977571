import ALARM_STATES from './alarmSettings'

const getPrio = states => {
	if (states.includes(ALARM_STATES.DEFROST)) return ALARM_STATES.DEFROST
	if (states.includes(ALARM_STATES.WARMUP)) return ALARM_STATES.WARMUP
	if (states.includes(ALARM_STATES.WARNING)) return ALARM_STATES.WARNING
	if (states.includes(ALARM_STATES.ALARM)) return ALARM_STATES.ALARM
	return ALARM_STATES.NO_ALARM
}

const determineState = (measuredValue, inputValue) => {
	if (inputValue === '2' && measuredValue === ALARM_STATES.ALARM) {
		// include '2' in defrost is due to old values, that were saved in this format
		return ALARM_STATES.DEFROST
	}
	if (inputValue !== '1') return ALARM_STATES.NO_ALARM
	return getPrio([measuredValue])
}

const prioritizeAlarms = measurements => {
	const currentState = getPrio(
		measurements.map(({ measuredValue, value }) =>
			determineState(measuredValue, value)
		)
	)
	return currentState
}

export default prioritizeAlarms
