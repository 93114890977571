import PropTypes from 'prop-types'
import DataTable from 'react-data-table-component'
import customStylesRows from '../../../shared/SimulateTable/customStyles'

const conditionalRowStyles = [
	{
		when: row => row.device.inactive,
		classNames: ['!text-gray-400'],
	},
]

const DataTableTemperature = ({ columns, data, print }) => {
	return (
		<DataTable
			fixedHeader={!print}
			fixedHeaderScrollHeight={!print ? 'calc(100vh - 17rem)' : undefined}
			highlightOnHover
			columns={columns}
			data={data}
			customStyles={customStylesRows}
			className={print ? '!overflow-hidden' : ''}
			conditionalRowStyles={conditionalRowStyles}
		/>
	)
}

DataTableTemperature.propTypes = {
	columns: PropTypes.array.isRequired,
	data: PropTypes.array.isRequired,
	print: PropTypes.bool,
}

export default DataTableTemperature
