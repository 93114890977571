import { format, getDaysInMonth } from 'date-fns'
import {
	combineName,
	easyData,
	finalInactiveSensors,
} from '../../../shared/format'
import { showTargetValue } from '../../../helper/ShowData'
import ALARM_STATES from './alarmSettings'
import prioritizeAlarms from './prioritizeAlarms'

const generateRows = (devicesTimeRange, systemJson) => {
	const { devices, timeRange } = devicesTimeRange
	const { startDate, months } = timeRange
	let { days } = timeRange
	const allRows = []

	if (months) days = getDaysInMonth(startDate)

	devices.forEach(device => {
		const { attributes, id, serial } = device

		for (let i = 0; i < days; i++) {
			const values = {}

			for (let hour = 0; hour <= 23; hour++) {
				values[`hour_${hour}`] = { alarm: null, temp: null }
			}

			const currentDay = new Date(startDate)
			currentDay.setDate(startDate.getDate() + i)

			allRows.push({
				day: format(currentDay, 'yyyy-MM-dd'),
				values,
				device: {
					name: combineName(attributes),
					id,
					serial,
					battery: parseInt(attributes.battery),
					installation_place: attributes.installation_place,
					inactive: finalInactiveSensors(
						systemJson.temperatureThresholds,
						attributes
					),
					target: showTargetValue(
						systemJson.temperatureThresholds.find(
							({ place }) => place === attributes.installation_place
						)
					),
				},
			})
		}
	})

	return allRows
}

const filterDataByState = (data, state) => {
	const dataWithZero = easyData(data.filter(d => d.measuredValue == state))
	const dataFilterZero = dataWithZero.filter(entry => entry.value !== '0')
	return dataFilterZero
}

const prepareTemperature = (
	data,
	temperatureData,
	devicesTimeRange,
	systemJson
) => {
	const defrostData = filterDataByState(data, ALARM_STATES.DEFROST)
	const warmingData = filterDataByState(data, ALARM_STATES.WARMUP)
	const warningData = filterDataByState(data, ALARM_STATES.WARNING)
	const alarmsData = filterDataByState(data, ALARM_STATES.ALARM)
	const processedTempData = easyData(temperatureData)

	const allRows = generateRows(devicesTimeRange, systemJson)

	allRows.forEach(row => {
		const filterRow = entry =>
			entry?.day === row?.day && entry.deviceId === row.device.id

		const currentRowData = {
			[ALARM_STATES.DEFROST]: defrostData.filter(filterRow),
			[ALARM_STATES.WARMUP]: warmingData.filter(filterRow),
			[ALARM_STATES.WARNING]: warningData.filter(filterRow),
			[ALARM_STATES.ALARM]: alarmsData.filter(filterRow),
			temp: processedTempData.filter(filterRow),
		}
		const alarmStates = [
			ALARM_STATES.ALARM,
			ALARM_STATES.WARNING,
			ALARM_STATES.DEFROST,
			ALARM_STATES.WARMUP,
		]

		const getHourValue = (type, hour) => {
			const entry = currentRowData[type].find(
				entry => `hour_${entry.hour}` === hour
			)
			return entry ? entry.value : '0'
		}

		for (const hour in row.values) {
			const measurements = alarmStates.map(state => ({
				measuredValue: state,
				value: getHourValue(state, hour),
			}))

			row.values[hour].alarm = prioritizeAlarms(measurements)

			const hourTempValue = currentRowData.temp.find(
				entry => `hour_${entry.hour}` === hour
			)?.value
			if (hourTempValue) {
				row.values[hour].temp = Number(hourTempValue).toFixed(1)
			}
		}
	})

	return allRows
		.toSorted((a, b) => a.device.name.localeCompare(b.device.name))
		.sort((a, b) => a.device.inactive - b.device.inactive)
}

export default prepareTemperature
