import ALARM_STATES from '../pages/app/Temperature/alarmSettings'
import prioritizeAlarms from '../pages/app/Temperature/prioritizeAlarms'

export const showTargetValue = props => {
	const target = props?.target
	if (!target) return '-'
	return target + ' °C'
}

export const tenantAddress = tenant => {
	const { attributes } = tenant.configuration
	let finalString = ''
	if (attributes.address) finalString += attributes.address + ', '
	if (attributes.zip) finalString += attributes.zip + ' '
	if (attributes.city) finalString += attributes.city
	return finalString
}

export const rolesSplited = rolesString => {
	const roles = rolesString?.split(',').map(role => role.trim()) || []

	const isAdminFilter = roles.filter(role => role === 'Administrator')
	const isAdmin = isAdminFilter.length > 0

	const usecasesPrefix = 'Device Types - '
	const usecasesFilter = roles.filter(role => role.startsWith(usecasesPrefix))
	const usecases = usecasesFilter.map(role => role.slice(usecasesPrefix.length))

	const usecasesIsAllFilter = usecases.filter(usecase => usecase === 'All')
	const usecasesIsAll = isAdmin ? true : usecasesIsAllFilter.length > 0

	const allRoles = {
		admin: isAdmin,
		usecases: { all: usecasesIsAll, roles: usecases },
	}
	return allRoles
}

export const getCurrentAlarmState = attributes => {
	if (!attributes) return null
	const relevantStates = [
		ALARM_STATES.DEFROST,
		ALARM_STATES.WARMUP,
		ALARM_STATES.WARNING,
		ALARM_STATES.ALARM,
	]

	const filteredMeasurements = Object.keys(attributes)
		.filter(key => relevantStates.includes(key))
		.map(key => ({
			measuredValue: key,
			value: attributes[key],
		}))
	const highestPriority = prioritizeAlarms(filteredMeasurements)
	return highestPriority
}
