import { format, subHours } from 'date-fns'

export const combineName = attributes => {
	if (!attributes) return ''
	if (attributes.installation_place === 'Sonstiges') {
		if (attributes.installation_place2) {
			return attributes.installation_place2
		} else {
			return attributes.installation_place
		}
	} else {
		return (
			attributes.installation_place + ' ' + (attributes.installation_number || 1)
		)
	}
}

export const easyData = data => {
	return data.map(({ timestamp, deviceId, value }) => {
		// Shift timestamp due to IOTA return
		const myDate = subHours(new Date(timestamp), 1)
		return {
			deviceId,
			day: format(myDate, 'yyyy-MM-dd'),
			hour: myDate.getHours(),
			value,
		}
	})
}

export const finalInactiveSensors = (temperatureThresholds, attributes) => {
	return temperatureThresholds.find(
		({ place }) => place === attributes?.installation_place
	)?.inactive
}
